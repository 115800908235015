import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, Form } from '@angular/forms';
import { ConcursoService } from '../services/concurso.service';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
//SERVICES
import { VideoService } from '../services/videos.service';
import { EntriesService } from '../services/entries.service';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
declare var $: any;

@Component({
  selector: 'videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  public nombreConcurso: string;
  public idConcurso: string;
  private idUsuario: string;
  public privilegios: number;
  public firstLoaded: boolean;
  public filtroCuentasVideo: string;
  public id_concurso_local: number;
  public idPruebaConcurso: string;
  public binomio_parametro: string;
  public pruebasConcurso: any;
  public binomios_pruebas_concurso: Array<any>;
  public clientes: any;
  public validadorCliente: UntypedFormControl;
  public videosPendientes: number;
  public insistencia: number;
  public videosForSale: boolean;
  private token: string;
  private sessionData: any;
  public selectFormControl: UntypedFormControl;
  public loading: Map<string, any>;
  public recorridos: string;

  constructor(
    private route: ActivatedRoute,
    private concursoService: ConcursoService,
    private authService: AuthService,
    private videoService: VideoService,
    private router: Router,
    private entriesService: EntriesService
  ) {
    this.videosForSale = false;
    this.token = '';
    this.idConcurso = this.authService.validarConcurso();
    this.idUsuario = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.filtroCuentasVideo = '';
    this.pruebasConcurso = [];
    this.binomios_pruebas_concurso = [];
    this.clientes = [];
    this.id_concurso_local = 0;
    this.videosPendientes = 0;
    this.idPruebaConcurso = '';
    this.insistencia = 0;
    this.binomio_parametro = this.route.snapshot.params.id_binomio||'';
    this.validadorCliente = new UntypedFormControl('', []);
    this.firstLoaded = false;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.idPruebaConcurso = this.route.snapshot.paramMap.get('id_prueba')||'';
    this.selectFormControl = new UntypedFormControl('', Validators.required);
    this.loading = new Map<string, any>();
    this.recorridos = '1';
  }

  ngOnInit() {
    $('#loader').show();
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (!(this.authService.validarConcurso() && (this.privilegios >= 300) || this.idUsuario == '630')) {
      this.router.navigate(['']);
      return;
    }
    this.getConcurso();
    this.getPruebasConcurso();
    setInterval(() => {
      this.getBinomiosPruebasConcurso(this.idPruebaConcurso);
    }, 30000);
  }

  ngAfterViewInit() {
  }

  public async getConcurso() {
    const response = await firstValueFrom(this.concursoService.getConcurso(this.idConcurso))
    if (response.error) {
      $('#loader').hide();
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
      return;
    }
    $('#loader').hide();
    this.videosForSale = response.concurso.videosForSale||false;
    console.log('videosForSale', this.videosForSale);
  }

  public async getPruebasConcurso() {
     const response = await firstValueFrom(this.videoService.getPruebasConcurso(this.idConcurso));
     this.pruebasConcurso = response;
     console.log('pruebasConcurso', this.pruebasConcurso);
  }

  public async getBinomiosPruebasConcurso(ipc: string) {
    if(!ipc){
      $('#loader').hide();
      this.binomios_pruebas_concurso = [];
      return;
    }
    const changed = ipc != this.idPruebaConcurso;
    if(changed){
      const prueba = this.pruebasConcurso.find(p => p.ipc == ipc);
      this.recorridos = prueba?.recorridos||'1';
      this.idPruebaConcurso = ipc;
    }

    const response = await firstValueFrom(this.videoService.getBinomiosPruebasConcurso(this.idPruebaConcurso));
    this.binomios_pruebas_concurso = response||[];
    if(changed){
      this.loading = new Map<string, any>(this.binomios_pruebas_concurso.map(b => [b.id_binomio, { loading1: false, loading2: false, error1: '', error2: '' }]));
    } else{
      const binomiosNuevos = this.binomios_pruebas_concurso.filter(b => ![...this.loading.keys()].includes(b.id_binomio));
      for(const b of binomiosNuevos){
        this.loading.set(b.id_binomio, { loading1: false, loading2: false, error1: '', error2: '' });
      }
    }
    const calificados = response.filter(b => b.calificado_uno).length + response.filter(b => b.calificado_dos).length;
    const calificados2 = this.binomios_pruebas_concurso.filter(b => b.calificado_uno).length + this.binomios_pruebas_concurso.filter(b => b.calificado_dos).length;
    this.videosPendientes = calificados - calificados2;
    console.log('binomios_pruebas_concurso', response);
    $('#loader').hide();
  }

  /**
   * Habilita u oculta el video en la APP.
   *
   * @param binomio ID del binomio al que se le cargo el video.
   * @param fase Fase de la prueba donde se grabo al binomio.
   */
  public async habilitarAcceso(binomio: string, fase: number, bloqueado: boolean){
    console.log('habilitarAcceso', binomio, fase, bloqueado);
    const bloqueo = !bloqueado;
    const oculto = bloqueo ? 1 : 0;
    const response = await firstValueFrom(this.videoService.habilitarAcceso(this.idPruebaConcurso, binomio, fase, oculto));
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
      return;
    }
    const i = this.binomios_pruebas_concurso.findIndex(b => b.id_binomio == binomio);
    if(fase == 1){
      this.binomios_pruebas_concurso[i].bloqueo1 = bloqueo;
      this.updateFS({ id_binomio: `us_${binomio}`, ipc: `us_${this.idPruebaConcurso}`, video1: !bloqueo });
    } else{
      this.binomios_pruebas_concurso[i].bloqueo2 = bloqueo;
      this.updateFS({ id_binomio: `us_${binomio}`, ipc: `us_${this.idPruebaConcurso}`, video2: !bloqueo });
    }
    if(bloqueo){
      $.NotificationApp.send("Hidden", "The customer does not have access to our video :(.", 'bottom-right', '#06d5a1', 'success');
    } else{
      $.NotificationApp.send("Public", "The customer can access this video :).", 'bottom-right', '#06d5a1', 'success');
      this.insistencia = 0;
    }
  }

  public async exclusivoOtomi(prueba, binomio) {
    const acceso = $('#video-otomi-' + binomio).is(':checked') ? 0 : 1;
    const accion = "exclusivoOtomi";
    const response = await firstValueFrom(this.videoService.exclusivoOtomi(binomio, prueba, acceso, accion));
    if (response.error) {
      $.NotificationApp.send("Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    if (acceso == 0) {
      $.NotificationApp.send("Exclusive", "This video is visible from any of our apps.", 'bottom-right', '#06d5a1', 'success');
    } else {
      $.NotificationApp.send("Public", "This video is only available from the Otomí app.", 'bottom-right', '#06d5a1', 'success');
    }
  }

  public fileDropped(e, id: number, fase: number) {
    const [video] = [...e.target.files||[]];
    console.log('fileDropped', video);
    if(!video) return;
    if(video.type == 'video/mp4'){
      //Subir directamente
      this.subirVideo(id, fase, video);;
    } else {
      //Error
      if(fase == 1){
        this.loading.get(`${id}`).loading1 = false;
        this.loading.get(`${id}`).error1 = 'Wrong filetype, try again.';
      } else{
        this.loading.get(`${id}`).loading2 = false;
        this.loading.get(`${id}`).error2 = 'Wrong filetype, try again.';
      }
    }
  }

  public async subirVideo(binomio: number, fase: number, video: File) {
    console.log('subirVideo', binomio, fase, video);
    //optimizar
    if(!this.idConcurso || !this.idPruebaConcurso || !binomio || !fase || !video){
      $.NotificationApp.send("Attention!", "To continue complete all fields.", 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    if (fase == 1){
      this.loading.get(`${binomio}`).loading1 = true;
      this.loading.get(`${binomio}`).error1 = '';
    } else if (fase == 2) {
      this.loading.get(`${binomio}`).loading2 = true;
      this.loading.get(`${binomio}`).error2 = '';
    }
    const bloqueo = this.videosForSale ? '1' : '0';
    const formData: FormData = new FormData();
    formData.append('concurso', this.idConcurso);
    formData.append('id_prueba', this.idPruebaConcurso);
    formData.append('binomio', `${binomio}`);
    formData.append('fase', `${fase}`);
    formData.append('video', video);
    formData.append('bloqueo', bloqueo);
    formData.append('accion', 'subirVideoBucket');
    $.ajax({
      method: "POST",
      url: `${environment.api.uri}videos/upload-video/`,
      contentType: false,
      processData: false,
      dataType: "json",
      data: formData,
      headers: {
        'x-api-key': '7cc263a1-a490-4337-8302-151490495e56'
      },
      success: (r) => {
        if(r.error){
          $.NotificationApp.send("Error", r.message, 'bottom-right', '#fa5c7c', 'error');
          if (fase == 1) {
            this.loading.get(`${binomio}`).loading1 = false;
          } else if (fase == 2) {
            this.loading.get(`${binomio}`).loading2 = false;
          }
          return;
        }
        $.NotificationApp.send("Uploaded", "The video has been successfully uploaded.", 'bottom-right', '#06d5a1', 'success');
        //Indice del binomio en el arreglo binomios_pruebas_concurso
        const i = this.binomios_pruebas_concurso.findIndex(b => b.id_binomio == binomio);
        if (fase == 1) {
          this.loading.get(`${binomio}`).loading1 = false;
          this.loading.get(`${binomio}`).error1 = '';
          this.binomios_pruebas_concurso[i] = { ...(this.binomios_pruebas_concurso[i]||{}), video_app: r.video_url, bloqueo1: this.videosForSale };
          this.updateFS({ id_binomio: `us_${binomio}`, ipc: `us_${this.idPruebaConcurso}`, video1: !this.videosForSale, video1Url: r.video_url });
        } else if (fase == 2) {
          this.loading.get(`${binomio}`).loading2 = false;
          this.loading.get(`${binomio}`).error2 = '';
          this.binomios_pruebas_concurso[i] = { ...(this.binomios_pruebas_concurso[i]||{}), video_app_2: r.video_url, bloqueo2: this.videosForSale };
          this.updateFS({ id_binomio: `us_${binomio}`, ipc: `us_${this.idPruebaConcurso}`, video2: !this.videosForSale, video2Url: r.video_url });
        }

      },
      error: (r) => {
        console.log(r);
        if (fase == 1) {
          this.loading.get(`${binomio}`).loading1 = false;
        } else if (fase == 2) {
          this.loading.get(`${binomio}`).loading2 = false;
        }
        $.NotificationApp.send("Error", "Unable to upload the video :(.", 'bottom-right', '#fa5c7c', 'error');
      }
    });
  }

  public async updateFS(entry: ResultFS){
    this.entriesService.setEntryFS(entry);
  }
}
